<template>
  <div class="contenuti"  id="tab2">
    <div v-if="!desktop"><slideshow2 v-if="slideCaricate" v-show="inHome" :immagini="slides" :home="inHome"/></div>
    <div v-if="mostraSpinner">
      <spinner />
    </div>
    <div v-else v-html="contenuti"></div>
    <div class="info" v-if="pag === 'cariche'">
    <router-link to="/info-cariche">SPIEGAZIONE DELLE MANSIONI DEI VARI INCARICHI</router-link>
    </div>
    <div class="puls_mobili">
    <div class="pulsante_galleria_mobile" v-if="pag === 'home'">
      GALLERIA FOTOGRAFICA
    </div>
    </div>
    <div v-show="mostraArticoli">
      <hr class="separatore">
      <keep-alive>
      <articoli :home="true"/>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Articoli from "@/componenti/articoli";
import Spinner from "@/componenti/spinner";
import slideshow2 from "@/componenti/slideshow2";
export default {
name: "pagina",
  components: {Spinner, Articoli, slideshow2},
  props: {
    pag: {
      type: String
    },
    desktop:  {
      type: Boolean
    }
  },
  data: function () {
    return{
      caricamento: false,
      now: 0,
      inHome: true,
      slideCaricate: false,
      inizioCaricamento: 0,
      mostraSpinner: false,
      contenuti: "",
      cartellaImmagini:"",
      mostraArticoli: false,
      win: "",
      chiave: -1,
      pagine: ['aed'],
      dati: [],
      slides: [],
      pagTitoli: [],
      pagDati: {
        nome: null,
        content: null
      }
    }
  },
  methods:  {
    caricaDati: function () {
      this.mostraArticoli = this.pag === "home";
      this.cartellaImmagini = this.$imageUrl + "pagine/" + this.pag;
      if (this.pagTitoli.includes(this.pag)) {
        this.contenuti = this.dati[this.pag];
      }else {
        this.contenuti = "";
        this.caricamento = true;
        this.inizioCaricamento = Math.round(Date.now() / 100);
        axios.post(this.$backend + `main.php`, {tipo: 'pagina', page: this.pag})
            .then(response => {
              this.contenuti = response.data.replaceAll("%img", this.cartellaImmagini);
              this.dati[this.pag] = this.contenuti;
              this.pagTitoli.push(this.pag);
            }).finally(()=>(
                this.caricamento = false,
                this.mostraSpinner = false));
      }
      // Immette il percorso corretto per le immagini
     // this.contenuti.replaceAll("%img", this.cartellaImmagini);
    },
    updateNow() {
      this.now = Math.round(Date.now() / 100)
      if (this.caricamento) {
        let diff = this.now - this.inizioCaricamento
        if (diff > 1) {
          this.mostraSpinner = true;
        }
      }
    },
    caricaSlide() {
      this.slideCaricate = false;
      axios.post(this.$backend + `main.php`, {tipo: 'slideshow_home', desktop: false})
          .then(response => {
            this.slides = response.data;
          }).finally(()=>(this.slideCaricate=true));
    },
  },
  created() {
  this.win = window.location.hostname;
  this.caricaDati();
  if (this.$route.path === "/") {
    this.caricaSlide();
  }
  },
  watch: {
    pag: function (to) { // Aggiorna i contenuti
      this.caricaDati();
      if (to === "home") {
        this.inHome = true;
        if (this.slideCaricate === false) {
          this.caricaSlide();
        }
      }else{
        this.inHome = false;
      }
    }
  },
  mounted () {
    this.updateNow();
    setInterval(this.updateNow.bind(this) , 100);
  }
}
</script>

<style scoped>
.info{
  text-align: center;
  padding-bottom: 20px;
  font-size: 1.2em;
}
#fountainG{
  position:relative;
  width:234px;
  height:28px;
  margin:auto;
}

.fountainG{
  position:absolute;
  top:0;
  background-color:rgb(0,0,0);
  width:28px;
  height:28px;
  animation-name:bounce_fountainG;
  -o-animation-name:bounce_fountainG;
  -ms-animation-name:bounce_fountainG;
  -webkit-animation-name:bounce_fountainG;
  -moz-animation-name:bounce_fountainG;
  animation-duration:1.035s;
  -o-animation-duration:1.035s;
  -ms-animation-duration:1.035s;
  -webkit-animation-duration:1.035s;
  -moz-animation-duration:1.035s;
  animation-iteration-count:infinite;
  -o-animation-iteration-count:infinite;
  -ms-animation-iteration-count:infinite;
  -webkit-animation-iteration-count:infinite;
  -moz-animation-iteration-count:infinite;
  animation-direction:normal;
  -o-animation-direction:normal;
  -ms-animation-direction:normal;
  -webkit-animation-direction:normal;
  -moz-animation-direction:normal;
  transform:scale(.3);
  -o-transform:scale(.3);
  -ms-transform:scale(.3);
  -webkit-transform:scale(.3);
  -moz-transform:scale(.3);
  border-radius:19px;
  -o-border-radius:19px;
  -ms-border-radius:19px;
  -webkit-border-radius:19px;
  -moz-border-radius:19px;
}

#fountainG_1{
  left:0;
  animation-delay:0.416s;
  -o-animation-delay:0.416s;
  -ms-animation-delay:0.416s;
  -webkit-animation-delay:0.416s;
  -moz-animation-delay:0.416s;
}

#fountainG_2{
  left:29px;
  animation-delay:0.5225s;
  -o-animation-delay:0.5225s;
  -ms-animation-delay:0.5225s;
  -webkit-animation-delay:0.5225s;
  -moz-animation-delay:0.5225s;
}

#fountainG_3{
  left:58px;
  animation-delay:0.619s;
  -o-animation-delay:0.619s;
  -ms-animation-delay:0.619s;
  -webkit-animation-delay:0.619s;
  -moz-animation-delay:0.619s;
}

#fountainG_4{
  left:88px;
  animation-delay:0.7255s;
  -o-animation-delay:0.7255s;
  -ms-animation-delay:0.7255s;
  -webkit-animation-delay:0.7255s;
  -moz-animation-delay:0.7255s;
}

#fountainG_5{
  left:117px;
  animation-delay:0.832s;
  -o-animation-delay:0.832s;
  -ms-animation-delay:0.832s;
  -webkit-animation-delay:0.832s;
  -moz-animation-delay:0.832s;
}

#fountainG_6{
  left:146px;
  animation-delay:0.9385s;
  -o-animation-delay:0.9385s;
  -ms-animation-delay:0.9385s;
  -webkit-animation-delay:0.9385s;
  -moz-animation-delay:0.9385s;
}

#fountainG_7{
  left:175px;
  animation-delay:1.035s;
  -o-animation-delay:1.035s;
  -ms-animation-delay:1.035s;
  -webkit-animation-delay:1.035s;
  -moz-animation-delay:1.035s;
}

#fountainG_8{
  left:205px;
  animation-delay:1.1415s;
  -o-animation-delay:1.1415s;
  -ms-animation-delay:1.1415s;
  -webkit-animation-delay:1.1415s;
  -moz-animation-delay:1.1415s;
}



@keyframes bounce_fountainG{
  0%{
    transform:scale(1);
    background-color:rgb(0,0,0);
  }

  100%{
    transform:scale(.3);
    background-color:rgb(255,255,255);
  }
}

@-o-keyframes bounce_fountainG{
  0%{
    -o-transform:scale(1);
    background-color:rgb(0,0,0);
  }

  100%{
    -o-transform:scale(.3);
    background-color:rgb(255,255,255);
  }
}

@-ms-keyframes bounce_fountainG{
  0%{
    -ms-transform:scale(1);
    background-color:rgb(0,0,0);
  }

  100%{
    -ms-transform:scale(.3);
    background-color:rgb(255,255,255);
  }
}

@-webkit-keyframes bounce_fountainG{
  0%{
    -webkit-transform:scale(1);
    background-color:rgb(0,0,0);
  }

  100%{
    -webkit-transform:scale(.3);
    background-color:rgb(255,255,255);
  }
}

@-moz-keyframes bounce_fountainG{
  0%{
    -moz-transform:scale(1);
    background-color:rgb(0,0,0);
  }

  100%{
    -moz-transform:scale(.3);
    background-color:rgb(255,255,255);
  }
}


</style>