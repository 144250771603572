<template>
  <div class="mobile_menu">
    <div id="container">
      <div class="spazio_header"></div>
      <div @click="puls" class="pulsante2_largo" id="phome">HOME</div>
      <div @click="redirect('https://arciconfraternitacorreggio.it/galleria/')" class="pulsante2_largo" id="pgalleria">GALLERIA FOTOGRAFICA</div>
      <div class="sezione" id="sez1">
        <div class="titolo_sezione">Storia e identità</div>
        <div class="tabulazione">
          <div class="cella"><div @click="apri" class="pulsante2 storia"><div @click="apri" class="pulsante_testo storia">STORIA</div></div></div>
          <div class="cella"><div @click="apri" class="pulsante2 attivita"><div @click="apri" class="pulsante_testo attivita">ATTIVITÀ</div></div></div>
          <div class="cella"><div @click="apri" class="pulsante2 insegne"><div @click="apri" class="pulsante_testo insegne">INSEGNE</div></div></div>
          <div class="cella"><div @click="apri" class="pulsante2 cronotassi"><div @click="apri" class="pulsante_testo cronotassi">CRONOTASSI</div></div></div>
        </div></div>
      <div class="sezione" id="sez2">
        <div class="titolo_sezione">Organizzazione</div>
        <div class="tabulazione">
          <div class="cella"><div @click="apri" class="pulsante2 cariche"><div @click="apri" class="pulsante_testo cariche">CARICHE</div></div></div>
          <div class="cella"><div @click="apri" class="pulsante2 consiglio"><div @click="apri" class="pulsante_testo consiglio">CONSIGLIO</div></div></div>
          <div class="cella"><div @click="apri" class="pulsante2 cappellani"><div @click="apri" class="pulsante_testo cappellani">CAPPELLANI</div></div></div>
          <div class="cella"><div @click="apri" class="pulsante2 statuto"><div @click="apri" class="pulsante_testo statuto">STATUTO</div></div></div>
        </div></div>
      <div class="sezione" id="sez3">
        <div class="titolo_sezione">Sede ed istituzioni</div>
        <div class="tabulazione">
          <div class="cella"><div @click="apri" class="pulsante2 oratorio"><div @click="apri" class="pulsante_testo oratorio">L'ORATORIO</div></div></div>
          <div class="cella"><div @click="apri" class="pulsante2 basilica"><div @click="apri" class="pulsante_testo basilica">LA BASILICA</div></div></div>
          <div class="cella"><div @click="apri" class="pulsante2 agiografia"><div @click="apri" class="pulsante_testo agiografia">SAN QUIRINO</div></div></div>
        </div></div>
    </div>
    <div @click="puls" class="pulsante2_largo" id="particoli">ARTICOLI</div>
    <a target="_blank" href="https://www.youtube.com/channel/UCvYNUEXjhAo8jDxAjYaJa2Q"> <div class="pulsante2_largo" id="pyoutube">CANALE YOUTUBE</div></a>
    <a target="_blank" href="https://www.facebook.com/pages/Oratorio-Mater-Amabilis/362345184131337"><div class="pulsante2_largo" id="pfacebook">PAGINA FACEBOOK</div></a>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "mobile_menu",
  methods: {
    puls(event){
      this.$emit('invmenu', event);
      if (event.target.id === 'phome') {this.$router.push('/');}
      if (event.target.id === 'particoli') {this.$router.push('/articoli');}
      //if (event.target.id === 'pgalleria') {this.$router.push('/galleria/');}
    },
    redirect: function (link) {
      window.open(link);
    },
    apri(event) {
      // Invia i dati dell'evenco al componente genitore
      this.$emit('clicked', event);
    }
    /* apri(event) {
       // Verifica se si sta leggendo un articolo
       let ind = this.$route.path;
       // Cerca la parola articoli nell'indirizzo
       let ver = ind.indexOf("articoli");
       if (ver !== -1) {

         this.$router.push('/');
       }
       let classePulsante =  event.target.className;
       let vec = classePulsante.split(' ');
       this.pagina = vec[1];
       this.$router.push(this.pagina);
       this.paginaCap = this.nomePag(this.pagina);
     },
     apriHome() {
       this.$router.push('/');
     },*/
  },
  mounted() {
    let cw2 = $('.pulsante2').height();
    $('.pulsante2').css({'width': cw2 + 'px'});
    // Carica le immagini del menu
    $("#pgalleria").css("background-image","url(" + this.$imageUrl +  "menu/galleria.png)");
    $(".pulsante2.storia").css("background-image","url(" + this.$imageUrl +  "menu/storia.jpg)");
    $(".pulsante2.attivita").css("background-image","url(" + this.$imageUrl +  "menu/attivita.jpg)");
    $(".pulsante2.insegne").css("background-image","url(" + this.$imageUrl +  "menu/vesti.jpg)");
    $(".pulsante2.cronotassi").css("background-image","url(" + this.$imageUrl +  "menu/cronotassi.jpg)");
    $(".pulsante2.cariche").css("background-image","url(" + this.$imageUrl +  "menu/cariche.jpg)");
    $(".pulsante2.consiglio").css("background-image","url(" + this.$imageUrl +  "menu/stemma.jpg)");
    $(".pulsante2.cappellani").css("background-image","url(" + this.$imageUrl +  "menu/cappellani.jpg)");
    $(".pulsante2.statuto").css("background-image","url(" + this.$imageUrl +  "menu/statuto.jpg)");
    $(".pulsante2.oratorio").css("background-image","url(" + this.$imageUrl +  "menu/oratorio.jpg)");
    $(".pulsante2.basilica").css("background-image","url(" + this.$imageUrl +  "menu/sanquirino_facciata.jpg)");
    $(".pulsante2.agiografia").css("background-image","url(" + this.$imageUrl +  "menu/reliquia.jpg)");
  }
}
</script>

<style scoped>
.mobile_menu{
  background-color: #262626;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  padding-bottom: 30px;
}

#container{
  width: 100%;
  /*max-width: 375px;*/
  padding: 10px 0 0;
  margin: 0;
  background-color: #262626;;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}



.spazio_header{
  /* Spazio vuoto della stessa dimensione dell'header necessario
  per non far coprire i pulsanti quando è nella posizione iniziale */
  width: 100%;
  height: 65px;
  margin:0;
}

.sezione{
  height: auto;
  width: 85%;
  padding: 0;
  margin-top: 20px;
  border-width: 2px;
  border-style: solid;
  border-radius: 10%;
}

#sez1{border-color: red;}
#sez2{border-color: yellow;}
#sez3{border-color: #BAA81F;}

.titolo_sezione{
  width: 100%;
  height: 25px;
  line-height: 30px;
  font-size:22px;
  text-align: center;
  color: white;
}

.tabulazione{
  width: 100%;
  height: 300px;
}

.cella{
  float: left;
  height: 50%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/************** Pulsanti *********************************************************/
.pulsante2_largo{
  /* Per il momento utilizzato solo per la galleria fotografica */
  height: 40px;
  line-height: 40px;
  width: 85%;
  max-width: 337px;
  font-size: 18px;
  text-align: center;
  padding: 0;
  margin: 8px auto 0;
  border-radius: 20px;
  color: white;
  background-size: 100%;
  cursor: pointer;
}
#phome{
  background-color: red;
}
#particoli{
  background-color: #5353e8;
  margin-top: 15px;
  margin-bottom: 15px;
}
#pfacebook{
  background-color: #2a5297;
}
#pyoutube{
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #ff0000;
}
.pulsante2{
  margin: 0 auto;
  height: 85%;
  max-height: 150px;
  background:  no-repeat;
  background-size: 100%;
  border: none !important;
  border-radius: 15%;
  cursor: pointer;
}
a{
  text-decoration: none;
  color: white;
}
a:hover{
  color: white;
}
.pulsante2_testo{
  opacity: 75%;
  position: relative;
  top: 72%;
  width: 100%;
  height: 20px;
  line-height: 20px;
  font-size: 15px;
  z-index: 9;
  color: #ffff;
  background-color: black;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 370px) {
  .pulsante2{
    height: 70%;
  }
  #container{
    margin-top:15px;
  }
}
@media screen and (min-width: 700px) {
  .tabulazione{
    height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .cella{
    height: 70%;
  }
  .pulsante2_largo{

    width: 500px;

  }
  .sezione{border-radius: 35px;}
  .tabulazione{padding-top: 10px;}

}


</style>