<template>
<header :class="{menu_attivo:this.menuAperto}">
  <div class="riga1">
  <img class="stemma" :src=" this.$imageUrl + 'stemma_100p.png' " alt="" />
  <div class="contenitore_titolo">
    <p id="tit" class="titolo">Arciconfraternita del SS. Sacramento</p>
  </div>

  <div id="pulsante_home">HOME</div>
  <!-- MENU TOGGLE BUTTON -->
    <button @click="invMenu" class="hamburger hamburger--spin" type="button">
      <a><div class="hamburger-box">
        <div class="hamburger-inner"></div>
      </div>
      </a>
    </button>
  </div>
  <div class="riga2">
    <p class="titolo">Arciconfraternita del SS. Sacramento</p>
  </div>
</header>
</template>

<script>
export default {
name: "intestazione-mobile",
  props:['menuAperto'],
  methods: {
  invMenu() {
    this.$emit('clicked');
  },
  }
}
</script>

<style scoped>
@import "../risorse/css/style.css";
main{
  margin-top: 60px;
}
.contenitore_titolo{
  text-align: center;
}

header{
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: #262626;
  color: white;
  z-index: 100;
}
header.menu_attivo{
  border-bottom: 1px solid white;
  background-color: rgba(38,38,38,0.7);
}
.riga1{
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.riga2{display: none;}
#pulsante_home{display: none;}
img.stemma{
  height: 75%;
  margin-left: 5vw;
}

@media screen and (max-width: 370px) {
  header{height: 90px;}
  main{
    margin-top: 80px;
  }
  #tit{display: none;}
  .riga2{
    display: block;
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
  }
  .titolo{
    margin: 0;
    font-size: 18px;
    font-family: sans-serif;
  }
}

.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  z-index:99999;}

.hamburger.attivo .hamburger-inner,
.hamburger.attivo .hamburger-inner::before,
.hamburger.attivo .hamburger-inner::after {
  background-color: white; }

.hamburger:hover, .hamburger:focus{
  background: unset;
  outline: unset;
}

.hamburger-box {
  width: 40px;
  height: 30px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: white;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease; }
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block; }
.hamburger-inner::before {
  top: -12px; }
.hamburger-inner::after {
  bottom: -12px; }
/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.attivo .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
.hamburger--spin.attivo .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
.hamburger--spin.attivo .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r.attivo .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
.hamburger--spin-r.attivo .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
.hamburger--spin-r.attivo .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

</style>