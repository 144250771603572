<template>
  <div>
    <div class="contenuti" id="tab2">
      <h1>{{dati.titolo}}</h1>

      <p class="data">{{dati.data}}9</p>
        <slideshow4 v-if="true" :immagini="dati.slides" />
    </div>
    <div v-html="dati.contenuti"></div>
  </div>
</template>

<script>
import axios from "axios";
import Slideshow4 from "@/componenti/slideshow4.vue";
// import Slideshow2 from "@/componenti/slideshow2.vue";
export default {
name: "articolo",
  components: {Slideshow4},
  props: {
    anno: {
      type: String,
      default: "0"
    },
    nome: {
      type: String,
      default: "Null"
    }
  },
  data: function () {
    return{
      a: "art",
      dati: [],
      mostraSlider: false,
      ms: false
    }
  },
  methods:  {
    caricaDati: function () {
      this.mostraSlider = false;
      axios.post(this.$backend + `main.php`, {tipo: 'articolo', anno: this.anno, nome: this.nome})
          .then(response => {
            this.dati = response.data;
          }).finally(()=>(
          this.mostraSlider=true));
    }
  },
  created() {
    this.caricaDati();
  },
  watch: {
    pag: function() { // Aggiorna i contenuti
      this.caricaDati();
    }
  }
}
</script>

<style scoped>
/*
.titolo_articolo{
  font-size: 1.4em;
  font-weight: bold;
}
.imgart{
  float: right;
  width: 160px;
  height: 160px;
}*/
.data{
  margin-top: 40px;
  color: #999999;
}

a{
  text-decoration: none;
  color:black;
}
a:hover{
  text-decoration: underline;
}


</style>