<template>
  <footer>
    <img class="ico_footer" :src=" this.$imageUrl + 'icona_lucchetto.png' " alt="" />
    <router-link to="/login">Area riservata</router-link>
    <img class="ico_footer" :src=" this.$imageUrl + 'icona_indirizzo.png' " alt="" /> Corso Mazzini n. 44, 42015 Correggio (RE)
    <img class="ico_footer" :src=" this.$imageUrl + 'icona_posta.png' " alt="" />
    <router-link to="/contatti">Contatti</router-link>
  </footer>
</template>

<script>
export default {
name: "footer-desktop"
}
</script>

<style scoped>
footer{
  position: fixed;
  bottom: 0;
  margin: 0;
  width: 100%;
  height: 40px;
  font-size: 18px;
  background-color: #262626;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

img.ico_footer{
  height: 50%;
  margin-left: 30px;
  margin-right: 10px;
}
</style>