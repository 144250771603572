<template>
  <div :class="{'contenuti': !home}"  id="tab2">
    <div v-if="lettura">
      <articolo :anno="this.anno" :nome="this.nome"/>
    </div>
      <div v-else class="lista">
        <div class="centrato"><h1>{{ nomePagina }}</h1></div>

        <div class="post" v-for="datiArticolo in articoli" v-bind:key="datiArticolo.id">
          <router-link :to=" '/articoli/' + datiArticolo.anno + '/' + datiArticolo.nome">
            <div v-if="datiArticolo.mostraImmagine">
              <img :src=" $imageUrl + datiArticolo.cartellaImmagini + datiArticolo.immagine" class="imgart" alt="npm" />
            </div>
          </router-link>
          <p class="titolo_articolo">
            <router-link :to=" '/articoli/' + datiArticolo.anno + '/' + datiArticolo.nome">
              {{datiArticolo.titolo}}
            </router-link>
          </p>
          <p class="data">{{datiArticolo.data}}</p>
        </div>
        <div v-if="mostraPulsante" class="cont_pulsante">
          <button @click="apriArt" class="pulsante_articoli2">Mostra tutti</button>
        </div>
      </div>
  </div>

</template>

<script>
import axios from "axios";
import articolo from "./articolo";
export default {
  name: "articoli",
  components: {
    articolo
  },
  props: {
    home: {
      type:Boolean,
      default:false
    },
    anno: {
      type: String,
      default: "0"
    },
    nome: {
      type: String,
      default: "Null"
    }
  },
  data: function () {
    return{
      nomePagina: "Articoli",
      articoli: [],
      datiArticolo : [],
      contenuti: "",
      classcont: true,
      lettura: false,
      mostraPulsante: false
    }
  },
  methods:  {
    lista: function () {
      axios.post(this.$backend + `main.php`, {tipo:'lista', anno: this.anno, home: this.home})
          .then(response => {
            this.articoli = response.data;
          })
    },
    caricaDati: function () {
      if (this.home === true) {
        this.nomePagina = "Ultimi articoli";
        this.mostraPulsante = true;
      }else {
        this.nomePagina = "Articoli";
        this.mostraPulsante = false;
      }
      this.lettura = (this.anno !== "0") && (this.nome !== "Null");
      if ((this.anno !== "0") && (this.nome === "Null")) {
        this.$router.push('/');
        this.$router.push('articoli');
      }
      if (this.lettura === false) {
        // Carica l'elenco degli articoli
        this.lista();
      }
    },
    apriArt() {
      this.$router.push('articoli');
    }
  },
  created() {
    this.caricaDati();

  },

  watch: {
    nome: function() { // Aggiorna i contenuti
      this.caricaDati();
    },
    anno: function() { // Aggiorna i contenuti
      this.caricaDati();
    }
  }
}
</script>

<style scoped>
.cont_pulsante {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.pulsante_articoli2{
  margin: 10px;
  font-size: 18px;
  line-height: 30px;
  height: 30px;
  width: 200px;
  border: none;
  border-radius: 10px;
  background-color: blue;
  color: white;
  cursor: pointer;
}
.pulsante_articoli2:hover{
  background-color: darkblue;
}
.lista{
  width: 78%;
  margin: 0 auto;
}
.post{
  padding-top:30px;
  padding-bottom:40px;
  width:100%;
  height:fit-content;
  border-top: 1px solid #dcdfe1;
  border-bottom: 1px solid #dcdfe1;
}
p.titolo_articolo{
  font-size: 1.4em;
  font-weight: bold;
  margin-left: 0;
}
p.data{
  margin-top: 40px;
  color: #999999;
  margin-left: 0;
}

a{
  text-decoration: none;
  color:black;
}
a:hover{
  text-decoration: underline;
}

.imgart{
  float: right;
  width: 160px;
  height: 160px;
}
@media screen and (max-width: 650px) {
  .imgart{
    float: left;
    margin-right: 50%;
    margin-bottom: 10px;
  }
  .post{
    padding-bottom:12px;
  }
}
</style>