<template>
  <header>
    <img class="stemma" :src=" this.$imageUrl + 'stemma_100p.png' " alt="" />
    <div class="contenitore_titolo">
      <p class="titolo">Arciconfraternita del Santissimo Sacramento</p>
      <p class="sottotitolo">con sede nell'oratorio "Mater amabilis" annesso
        all'insigne basilica collegiata dei santi Quirino e Michele Arcangelo in Correggio</p>
    </div>
    <div class="menu_sel">
      <div id="animation_t">
        <div id="ts1">
          <div class="titolo_sezione">Storia e identità</div>
          <div class="tabulazione">
            <div @click="apri" class="pulsante storia"><div @click="apri" class="pulsante_testo storia">STORIA</div></div>
            <div @click="apri" class="pulsante attivita"><div @click="apri" class="pulsante_testo attivita">ATTIVITÀ</div></div>
            <div @click="apri" class="pulsante insegne"><div @click="apri" class="pulsante_testo insegne">INSEGNE</div></div>
            <div @click="apri" class="pulsante cronotassi"><div @click="apri" class="pulsante_testo cronotassi">CRONOTASSI</div></div>
          </div>
        </div><div id="ts2">
        <div class="titolo_sezione">Organizzazione</div>
        <div class="tabulazione">
          <div @click="apri" class="pulsante cariche"><div @click="apri" class="pulsante_testo cariche">CARICHE</div></div>
          <div @click="apri" class="pulsante consiglio"><div @click="apri" class="pulsante_testo consiglio">CONSIGLIO</div></div>
          <div @click="apri" class="pulsante cappellani"><div @click="apri" class="pulsante_testo cappellani">CAPPELLANI</div></div>
          <div @click="apri" class="pulsante statuto"><div @click="apri" class="pulsante_testo statuto">STATUTO</div></div>
        </div>
      </div><div id="ts3">
        <div class="titolo_sezione">Sede ed istituzioni</div>
        <div class="tabulazione">
          <div @click="apri" class="pulsante oratorio"><div @click="apri" class="pulsante_testo oratorio">L'ORATORIO</div></div>
          <div @click="apri" class="pulsante basilica"><div @click="apri" class="pulsante_testo basilica">LA BASILICA</div></div>
          <div @click="apri" class="pulsante agiografia"><div @click="apri" class="pulsante_testo agiografia">SAN QUIRINO</div></div>
        </div>
      </div>
      </div>
    </div>
    <div class="intestazioneRidotta" v-show="vis">
      <span v-if="categoria===1">Storia e identità</span>
      <span v-if="categoria===2">Organizzazione</span>
      <span v-if="categoria===3">Sede ed istituzioni</span>
      &#10095; {{pagnome}}
    </div>
    <div id="pulsante_home">HOME</div>
    <!-- MENU TOGGLE BUTTON -->
    <svg @click="apriMenu" v-show="false" class="menuicon" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
      <g>
        <line class="menuicon__bar" x1="13" y1="16.5" x2="37" y2="16.5"/>
        <line class="menuicon__bar" x1="13" y1="24.5" x2="37" y2="24.5"/>
        <line class="menuicon__bar" x1="13" y1="24.5" x2="37" y2="24.5"/>
        <line class="menuicon__bar" x1="13" y1="32.5" x2="37" y2="32.5"/>
        <circle class="menuicon__circle" r="23" cx="25" cy="25" />
      </g>
    </svg>
  </header>
</template>

<script>
import $ from "jquery";

export default {
name: "intestazione",
  props: ['inHome', 'categoria', 'pagnome', 'vis'],
  data: function () {
    return {
      menuAperto: false
    }
  },
  methods: {
  apriMenu(event) {
    this.$emit('clicked', event);
  },
    apri(event) {
      this.$emit('clicked', event);
    }
  },
  mounted() {
    let cw;
    cw = $('.pulsante').height();
    $('.pulsante').css({'width': cw + 'px'});
    // Carica le immagini del menu
    $(".pulsante.storia").css("background-image","url(" + this.$imageUrl +  "menu/storia.jpg)");
    $(".pulsante.attivita").css("background-image","url(" + this.$imageUrl +  "menu/attivita.jpg)");
    $(".pulsante.insegne").css("background-image","url(" + this.$imageUrl +  "menu/vesti.jpg)");
    $(".pulsante.cronotassi").css("background-image","url(" + this.$imageUrl +  "menu/cronotassi.jpg)");
    $(".pulsante.cariche").css("background-image","url(" + this.$imageUrl +  "menu/cariche.jpg)");
    $(".pulsante.consiglio").css("background-image","url(" + this.$imageUrl +  "menu/stemma.jpg)");
    $(".pulsante.cappellani").css("background-image","url(" + this.$imageUrl +  "menu/cappellani.jpg)");
    $(".pulsante.statuto").css("background-image","url(" + this.$imageUrl +  "menu/statuto.jpg)");
    $(".pulsante.oratorio").css("background-image","url(" + this.$imageUrl +  "menu/oratorio.jpg)");
    $(".pulsante.basilica").css("background-image","url(" + this.$imageUrl +  "menu/sanquirino_facciata.jpg)");
    $(".pulsante.agiografia").css("background-image","url(" + this.$imageUrl +  "menu/reliquia.jpg)");
    $(window).scroll(function () {
      if ($(document).scrollTop() > 0) {
        $('header').addClass('small');
        $('.parte_sx, .parte_dx').addClass('alzata');
      } else {
        $('header').removeClass('small');
        $('.parte_sx, .parte_dx').removeClass('alzata');
      }
    });
  }
}

</script>

<style scoped>

header{
  position: fixed;
  top: 0;
  width: 100%;
  height: 170px;
  background-color: #262626;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  z-index: 100;
}

header.small{
  height: 60px;
  transition:  height 0.5s;
  -webkit-transition:  height 0.5s;
  -moz-transition: height 0.5s;
}

header.small img.stemma{
  height: 55px;
  transition:  height 0.5s;
  -webkit-transition:  height 0.5s;
  -moz-transition: height 0.5s;
}
header.small .menu_sel{display: none;}
header.small .sottotitolo{display: none;}

.intestazioneRidotta {
  display: none;
  margin-left: 200px;
  font-size: 20px;
  height: 60px;
  line-height: 60px;
}
header.small .intestazioneRidotta{display: block;}
/*header.small .freccia_super{display: block;}*/
#pulsante_home{
  display: none;
}

img.stemma{
  margin-left: 40px;
  margin-right: 30px;
  height: 111px;
}

.contenitore_titolo{
  height: 100%;
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Palatino, serif;
  /*flex-wrap: wrap;*/
}

.titolo{
  font-size: 30px;
  font-variant:small-caps;
  margin: 0 0 15px;
  /* height: 40px;
   line-height:40px;
   text-align: left;*/
  font-weight: lighter;
}
header.small .titolo {
  line-height: 60px;
  padding-bottom: 10px;
  margin-bottom: 0;
}

.sottotitolo{
  margin: 0;
  font-size: 16px;
  font-style: italic;
  width: 490px;
}
.menu_sel{
  width: 600px;
  height: 145px;
  margin-left: 30px;
  padding:0;
  overflow: hidden;
  border: 2px solid red;
  border-radius: 15px;
}
.tabulazione{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>