import Vue from 'vue'
import App from './App.vue'
import router from './router.js'

Vue.config.productionTip = false;
Vue.prototype.$hostname = window.location.hostname;
if ((Vue.prototype.$hostname === "localhost") || (Vue.prototype.$hostname === "127.0.0.1")) {
 // Vue.prototype.$url = "http://127.0.0.1/confraternita";
  Vue.prototype.$url = "http://www.arciconfraternitacorreggio.it";
}else{
    Vue.prototype.$url =  'https://' + Vue.prototype.$hostname;
//  Vue.prototype.$url = 'http://' + Vue.prototype.$hostname;
}
Vue.prototype.$sessione = "1";
Vue.prototype.$base = "";
/*
if(Vue.prototype.$host === "localhost") {
  Vue.prototype.$url = "http://127.0.0.1/confraternita";
}
if(Vue.prototype.$host === "127.0.0.1") {
  Vue.prototype.$url = "http://127.0.0.1/confraternita";
  Vue.router.base = "confraternita/dist";
}*/

Vue.prototype.$imageUrl = Vue.prototype.$url + "/immagini/"
Vue.prototype.$backend =  Vue.prototype.$url + "/contenuti/"

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
