<template>
  <footer>
    <img class="ico_footer" :src=" this.$imageUrl + 'icona_posta.png' " alt="" />
    <router-link to="/contatti">Contatti</router-link>
  </footer>
</template>

<script>
export default {
name: "footer-mobile"
}
</script>

<style scoped>
footer{
  position: relative;
  bottom: 0;
  margin: 0;
  width: 100%;
  height: 60px;
  font-size: 19px;
  background-color: #262626;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

img.ico_footer{
  height: 30%;
  margin-right: 7px;
}
a{
  text-decoration: none;
  color: white;
  user-focus: none;
}
a.hover{
  color: white;
}
</style>