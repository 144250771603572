<template>
    <flickity  class="carousel" ref="flickity"  :options="flickityOptions">
      <div class="carousel-cell" :class="{carousel_home: home}" v-for="(slide, index) in immagini" :key="index">
        <img class="carousel-cell-image" :data-flickity-lazyload="percorso + slide" alt="tulip" />
      </div>
    </flickity>
</template>

<script>
import Flickity from 'vue-flickity';

export default {
  name: "slideshow2",
  components: {
    Flickity
  },
  props: {
    immagini: {
      type: Array,
    },
    home:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      percorso: this.$imageUrl,
      mostraSlides: false,
      flickityOptions: {
        wrapAround: true,
        draggable: false,
        lazyLoad: 2,
        adaptiveHeight: true
      }
    }
  },

  methods: {
    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    }
  },
  created() {

  }
}
</script>

<style scoped>
/* external css: flickity.css */

* { box-sizing: border-box; }

body { font-family: sans-serif; }

.carousel {
  background:  black;
  margin-bottom: 40px;
}

.carousel-cell {
  height: 400px;
  width: fit-content;
  margin-right: 8px;
  margin-left: 8px;
  background: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.carousel_home{
  width: fit-content;
  margin: 0;
}
.carousel-cell-image {
  display: block;
  max-height: 100%;
  max-width: 100%;
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

/* fade in lazy loaded image */
.carousel-cell-image.flickity-lazyloaded,
.carousel-cell-image.flickity-lazyerror {
  opacity: 1;
}
@media screen and (max-width: 700px) {
  .carousel-cell{
    height: auto;
  }
}
</style>
