<template>
  <div class="form-signin">
    <h1 class="centrato">Pannello di controllo</h1>
    <div class="login">
      <input type="text" id="inputEmail" class="form-control" placeholder="Nome utente" required>
      <input type="password" id="inputPassword" class="form-control" placeholder="Password" required>
      <div class="checkbox mb-3">
        <div class="mantieni-login">
          <input class="mc" type="checkbox" value="mantieni-connessione"> Rimani collegato
        </div>
      </div>
      <div class="accesso-negato" v-if="a">Combinazione utente / password errata</div>
      <button @click="login" class="button-login">Accedi</button>
    </div>
  </div>
</template>

<script>
export default {
name: "login",
  data: function () {
    return {
      a: false
    }
  },
  methods:{
  login() {
    // Questa funzione deve essere ancora sviluppata
    this.a = true;
  }
  }
}
</script>

<style scoped>
.login{
  width: 290px;
  margin: 0 auto;
}
.mantieni-login{
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1em;
}

.mc{
  margin-right: 7px;
}
 .button-login{
   width: 100%;
   background: #5353e8;
   text-align: center;
   height: 50px;
   line-height: 50px;
   font-size: 22px;
   color: white;
   border: none;
   border-radius: 25px;
 }
 .button-login:hover{
   background-color: #003abc;
 }
 .form-control{
   width: 100%;
   height: 35px;
   margin: 0;
   padding-left: 7px;
 }
 .form-control:focus{
   outline-color: blue;
   outline-width: 6px;
 }
.form-signin{
  width: 500px;
  margin: 0 auto;
  padding-top: 30px;
}
.accesso-negato{
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  color: red;
}
</style>