<template>



 <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img class="d-block w-100" src="https://arciconfraternitacorreggio.it/immagini/slideshow/1.jpg" alt="First slide">
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="https://arciconfraternitacorreggio.it/immagini/slideshow/2.jpg" alt="Second slide">
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="https://arciconfraternitacorreggio.it/immagini/slideshow/3.jpg" alt="Third slide">
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>

</template>

<script>

export default {
  name: "slideshow2",
  components: {
  },
  props: {
    immagini: {
      type: Array,
    },
    home:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      percorso: this.$imageUrl,
      mostraSlides: false,
      flickityOptions: {
        wrapAround: true,
        draggable: false,
        lazyLoad: 2,
        adaptiveHeight: true
      }
    }
  },

  methods: {

  },
  created() {

  }
}
</script>

<style scoped>
@import '../risorse/css/bootstrap.css';
/* external css: flickity.css */

* { box-sizing: border-box; }

body { font-family: sans-serif; }

.carousel {
  background:  black;
  margin-bottom: 40px;
}

.carousel-cell {
  height: 400px;
  width: fit-content;
  margin-right: 8px;
  margin-left: 8px;
  background: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.carousel_home{
  width: fit-content;
  margin: 0;
}
.carousel-cell-image {
  display: block;
  max-height: 100%;
  max-width: 100%;
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

/* fade in lazy loaded image */
.carousel-cell-image.flickity-lazyloaded,
.carousel-cell-image.flickity-lazyerror {
  opacity: 1;
}
@media screen and (max-width: 700px) {
  .carousel-cell{
    height: auto;
  }
}

</style>
