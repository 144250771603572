<script>
import $ from 'jquery'
import axios from "axios";
export default {
  name: "slideshow",
  data: function () {
    return{
      var1: "",
      var2: "",
      autoslide: true,
      numeroSlide: 1,
      totSlide: 5,
      slideCaricate: false,
      slides: []
    }
  },
  methods: {
    caricaDati() {
      this.slideCaricate = false;
      axios.post(this.$backend + `main.php`, {tipo: 'slideshow_home', desktop: true})
          .then(response => {
            this.slides = response.data;
          }).finally(()=>(this.slideCaricate=true));
    }
    },
  created() {
    this.caricaDati();
  },
  mounted() {
    /* Altezza dello slideshow per fotografie con rapporto d'aspetto pari a 16:9 */
    let chr = $('.slideshow').width();
    let ch = chr * 9 / 16;
    $('.slideshow').css({'height': ch + 'px'});
    /* Slideshow */
    //**************** Parametri *******************************************
    const totSlide = this.totSlide;
    const tempoAgg = 10; // Intervallo di aggiornamento della barra circolare in millisecondi
    const tempoSlide = 5000; // Tempo per cui viene mostrata la slide in ms
    let autoslide = true;
    //**********************************************************************
    let tempoTimer = 0;
    let perc = 0;
    let numeroSlide = 1;
    let nuovaSlide;
    caricaSlide(1); // Caricamento della prima immagine
    $("#slide1").addClass("attiva");
    function caricaSlide(num) {
      /********************** LAZY LOADING ***************************/
      let nid, diff;
      $('.lazy').each(function(){
        nid = parseInt(this.id.replace("slide",""));
        diff = Math.abs(nid - num);
        if (diff < 2) { // Carica l'immagine richiesta (se non già caricata) e quella successiva
          if (nid !== 0) {
            this.src = this.dataset.src;
            this.classList.remove('lazy');
          }
        }
      });
    }

    function mostraSlide(num) {
      tempoTimer = 0;
      perc = 0;
      mostraSlide2(num);
    }

    function mostraSlide2(num) {
      caricaSlide(num);
      if (num !== numeroSlide) {
        $('#slide' + num).addClass('attiva');
        $('#slide' + numeroSlide).removeClass('attiva');
        $('#p' + num).addClass('attivo');
        $('#p' + numeroSlide).removeClass('attivo');
        numeroSlide = num;
      }
    }
    $('.punto').click(function () {
      let numero = $(this).attr('id').replace("p","");
      mostraSlide(numero);
    });

    $('.freccia_destra').click(function () {
      nuovaSlide = numeroSlide + 1;
      if (nuovaSlide > totSlide) {
        nuovaSlide = 1;
      }
      mostraSlide(nuovaSlide);
    });
    $('.freccia_sinistra').click(function () {
      nuovaSlide = numeroSlide - 1;
      if (nuovaSlide === 0) {
        nuovaSlide = totSlide;
      }
      mostraSlide(nuovaSlide);
    });
    /* Slideshow controller */
    let control = document.getElementById('control');
    this.var1 = control;
    let progressValue = document.querySelector('.progress__value');
    let RADIUS = 54;
    let CIRCUMFERENCE = 2 * Math.PI * RADIUS;

    function progress(value) {
      let progress = value / 100;
      let dashoffset = CIRCUMFERENCE * (1 - progress);
      progressValue.style.strokeDashoffset = dashoffset;
    }

    progressValue.style.strokeDasharray = CIRCUMFERENCE;


    const interval = setInterval(function () {
      if (autoslide) {
        progress(perc);
        tempoTimer += tempoAgg;
        perc = 100 * tempoTimer / tempoSlide;
        if (perc >= 100) {
          perc = 0;
          tempoTimer = 0;
          nuovaSlide = numeroSlide + 1;
          if (nuovaSlide > totSlide) {
            nuovaSlide = 1;
          }
          mostraSlide2(nuovaSlide);
        }
      }
    }, tempoAgg);
this.var2 = interval;
    $('.puls').click(function () {
      if (autoslide) { // Ferma l'avanzamento automatico delle slide
        autoslide = false
        progress(0);
        $("#pausa").css("display", "none");
        $("#play").css('display', 'block');
      } else {           // Riparte l'avanzamento automatico delle slide
        autoslide = true;
        perc = 0;
        tempoTimer = 0;
        $("#play").css('display', 'none');
        $("#pausa").css('display', 'block');
      }
    });
  }
}
</script>

<template>
<div class="slideshow">
    <div class="slideControl">
      <img :src=" this.$imageUrl + 'pausa.png' " class="puls" id="pausa" />
      <img :src=" this.$imageUrl + 'play.png' " class="puls" id="play" />
      <svg class="progress" width="100%" height="100%" viewBox="0 0 120 120">
        <circle class="progress__meter" cx="60" cy="60" r="54" stroke-width="12" />
        <circle class="progress__value" cx="60" cy="60" r="54" stroke-width="12" />
      </svg>
    </div>
    <div class="freccia_destra"><span>&#10095;</span></div>
    <div class="freccia_sinistra"><span>&#10094;</span></div>
  <div class="contenitore_punti">
    <div v-for="i in totSlide" :key="i" class="punto" :class="{'attivo':i===1}" :id="'p'+i"></div>
  </div>
  <div v-if="slideCaricate">
    <img class="slide attiva" id="slide1" :src="$imageUrl + slides[1]" alt="">
    <img v-for="(slide, index) in slides.slice(0)" :key="index" class="lazy slide" :id="'slide'+ index" :data-src="$imageUrl + slide"  alt="">
  </div>
</div>
</template>

<style scoped>
.slideshow{
  width: 55vw;
  max-width: 1200px;
  margin: 0 auto 20px;
  position: relative
}

.slide{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s;
}

.attiva{
  opacity: 1;
  transition: opacity 1s;
}

.freccia_sinistra, .freccia_destra {
  position: absolute;
  z-index: 8;
  top: 35%;
  height: 30%;
  width: 50px;
  font-size: 27px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  cursor:pointer;
  user-select: none;
}

.freccia_sinistra:hover, .freccia_destra:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.freccia_destra{right: 0;}

.contenitore_punti{
  position: absolute;
  z-index: 9;
  bottom: 0;
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.punto{
  width: 12px;
  height: 12px;
  background-color: rgba(0,0,0,0);
  margin-left: 8px;
  margin-right: 8px;
  border: 2px solid white;
  border-radius: 50%;
  cursor: pointer;
}

.attivo{
  background-color: white;
}

.slideControl{
  position: absolute;
  z-index: 12;
  top:8px;
  left: 8px;
  width: 40px;
  height: 40px;
  /*  background: url("../immagini/pausa.png") no-repeat center;*/
}

.progress {
  transform: rotate(-90deg);
}

.progress__meter,
.progress__value {
  fill: none;
}

.progress__meter {
  stroke: #C8C8C8;
  fill: black;
  fill-opacity: 0.2;
}

.progress__value {
  stroke: #A18400;
  stroke-linecap: round;
}

</style>