<template>
  <div id="app">
    <mobile_menu v-if="menuAperto" @clicked="onChildClick" @invmenu="invMenu"/>
    <intestazione v-if="desktop" @clicked="onChildClick" :inHome="inHome" :categoria="categoria" :key="val" :pagnome="pagnome" :vis="vis"/>
    <intestazione_mobile v-if="!desktop" @clicked="invMenu" :key="val" :menuAperto="menuAperto"/>
    <main v-show="!menuAperto">
      <div class="parte_sx">
        <div @click="apriHome" class="pulsante_home" id="homelink">HOME</div>
        <a class="pulsante_link" href="https://arciconfraternitacorreggio.it/galleria/index.php">
          <div class="pulsante_home" style="background-color: black;">GALLERIA FOTOGRAFICA</div>
        </a>
        <div class="sezione_sinistra">
          <div id="animation_s">
            <div class="titolo_sezione">Organizzazione</div>
            <div class="tab">
              <div class="cella"><div @click="apri" class="pulsante cariche"><div @click="apri" class="pulsante_testo cariche">CARICHE</div></div></div>
              <div class="cella"><div @click="apri" class="pulsante consiglio"><div @click="apri" class="pulsante_testo consiglio">CONSIGLIO</div></div></div>
              <div class="cella"><div @click="apri" class="pulsante cappellani"><div @click="apri" class="pulsante_testo cappellani">CAPPELLANI</div></div></div>
              <div class="cella"><div @click="apri" class="pulsante statuto"><div @click="apri" class="pulsante_testo statuto">STATUTO</div></div></div>
            </div><div class="spazio"></div>
            <div class="titolo_sezione">Storia e identità</div>
            <div class="tab">
              <div class="cella"><div @click="apri" class="pulsante storia"><div @click="apri" class="pulsante_testo storia">STORIA</div></div></div>
              <div class="cella"><div @click="apri" class="pulsante attivita"><div @click="apri" class="pulsante_testo attivita">ATTIVITÀ</div></div></div>
              <div class="cella"><div @click="apri" class="pulsante insegne"><div @click="apri" class="pulsante_testo insegne">INSEGNE</div></div></div>
              <div class="cella"><div @click="apri" class="pulsante cronotassi"><div @click="apri" class="pulsante_testo cronotassi">CRONOTASSI</div></div></div>
            </div>
          </div>
        </div>
        <router-link to="/articoli" class="pulsante_link"> <div class="pulsante_articoli">ARTICOLI</div></router-link>
      </div>
      <div v-if="desktop"><slideshow v-show="inHome"/></div>
      <keep-alive>
        <router-view :desktop="desktop"/>
      </keep-alive>
      <div  class="parte_dx">
        <a class="pulsante_link" target="_blank" href="https://www.youtube.com/channel/UCvYNUEXjhAo8jDxAjYaJa2Q">
          <div class="pulsante_home" style="background-color: red;">CANALE YOUTUBE</div>
        </a>
        <a class="pulsante_link" target="_blank" href="https://www.facebook.com/pages/Oratorio-Mater-Amabilis/362345184131337">
          <div class="pulsante_home" style="background-color: #2A5297;">PAGINA FACEBOOK</div>
        </a>
        <div class="sezione_destra" id="sez3">
          <div id="animation_d">
            <div class="titolo_sezione">Sede ed istituzioni</div>
            <div class="tab">
              <div class="cella"><div @click="apri" class="pulsante oratorio"><div @click="apri" class="pulsante_testo oratorio">L'ORATORIO</div></div></div>
              <div class="cella"><div @click="apri" class="pulsante basilica"><div @click="apri" class="pulsante_testo basilica">LA BASILICA</div></div></div>
              <div class="cella"><div @click="apri" class="pulsante agiografia"><div @click="apri" class="pulsante_testo agiografia">SAN QUIRINO</div></div></div>
            </div><div class="spazio"></div>
            <div class="titolo_sezione">Organizzazione</div>
            <div class="tab">
              <div class="cella"><div @click="apri" class="pulsante cariche"><div @click="apri" class="pulsante_testo cariche">CARICHE</div></div></div>
              <div class="cella"><div @click="apri" class="pulsante consiglio"><div @click="apri" class="pulsante_testo consiglio">CONSIGLIO</div></div></div>
              <div class="cella"><div @click="apri" class="pulsante cappellani"><div @click="apri" class="pulsante_testo cappellani">CAPPELLANI</div></div></div>
              <div class="cella"><div @click="apri" class="pulsante statuto"><div @click="apri" class="pulsante_testo statuto">STATUTO</div></div></div>
            </div><div class="spazio"></div>
          </div>
        </div>
        <router-link to="/onlus" class="pulsante_link"> <div class="pulsante_articoli">ONLUS</div></router-link>
      </div>

      <div class="phantom"></div>

    </main>
    <!-- Pulsante per tornare ad inizio pagina -->
    <a v-if="desktop" id="topbutton"><img alt="top" :src="this.$imageUrl + 'freccia.png'"></a>
    <footer_desktop v-if="desktop" />
    <footer_mobile v-else />
  </div>
</template>

<script>
import Vue from 'vue';
import VueRouter from 'vue-router';
import $ from 'jquery'
import Slideshow from "@/componenti/slideshow";
import mobile_menu from "@/componenti/mobile_menu";
import intestazione from "@/componenti/intestazione";
import intestazione_mobile from "@/componenti/intestazione_mobile";
import footer_desktop from "@/componenti/footer-desktop";
import footer_mobile from "@/componenti/footer-mobile";
import VueMobileDetection from "vue-mobile-detection";
import axios from "axios";

Vue.use(VueMobileDetection);
Vue.use(VueRouter);

export default {
  name: 'App',
  components: {
    intestazione, intestazione_mobile,
    Slideshow, mobile_menu, footer_desktop, footer_mobile
  },
  data: function () {
    return{
      desktop: false,
      inHome: true,
      menuAperto: false,
      imgUrl: this.$imageUrl,
      vis: false, // Se vera l'intestazione ridotta diventa visibile quando si scorre la pagina
      pagina: "",
      pagnome: "",
      categoria: 0,
      categoria1: "storia attivita vesti insegne cronotassi",
      categoria2: "cariche consiglio cappellani statuto",
      categoria3: "oratorio basilica agiografia",
      window_width: window.innerWidth,
      ds: false,
      val: 0,
      slides:[],
      slideCaricate: false
    }
  },
  methods:{
    onResize() {
      this.window_width = window.innerWidth;
      this.ds = this.desktop;
      this.desktop = this.window_width > 1100;
      if (this.ds !== this.desktop) {
        this.val += 1;
      }
    },
    onChildClick(value) {
      this.apri(value);
    },
    nomePag(stringa) {
      stringa = stringa.replaceAll("/","");
      if (stringa === "attivita") {
        stringa = "attività";
      }
      return stringa.charAt(0).toUpperCase() + stringa.slice(1);
    },
    invMenu() {
      this.menuAperto = !this.menuAperto;
      $('.hamburger--spin').toggleClass('attivo');
    },
    apri(event) {
      this.menuAperto = false;
      $('.hamburger--spin').removeClass('attivo');
      // Verifica se si sta leggendo un articolo
      let ind = this.$route.path;
      // Cerca la parola articoli nell'indirizzo
      let ver = ind.indexOf("articoli");
      if (ver !== -1) {
        /* se la trova il valore della variabile è
         diverso da -1, in tal caso ritorna prima alla pagina degli articoli*/
        this.$router.push('/articoli');
      }
      let classePulsante =  event.target.className;
      let vec = classePulsante.split(' ');
      this.pagina = vec[1];
      if (this.categoria1.includes(this.pagina)) {this.categoria = 1;}
      if (this.categoria2.includes(this.pagina)) {this.categoria = 2;}
      if (this.categoria3.includes(this.pagina)) {this.categoria = 3;}
      this.$router.push('/' + this.pagina);
      $('html,body').scrollTop(0);
    },
    apriHome() {
      this.menuAperto = false;
      this.$router.push('/');
    },
    apriMenu() {
      this.menuAperto = this.menuAperto === false;
    },
    caricaSlide() {
      this.slideCaricate = false;
      axios.post(this.$backend + `main.php`, {tipo: 'slideshow_home'})
          .then(response => {
            this.slides = response.data;
          }).finally(()=>(this.slideCaricate=true));
    },
    coloraBordo(url) {
      // Colora il contorno dell'icona corrispondente alla pagina attiva
      const color1 = 'red';
      const color2 = 'yellow';
      const color3 = '#BAA81F';
      let colore = '';
      let percorso = url.replace("/", "");
      let pres = 0;
      if (percorso === "") {
        pres = 0; // Si trova nella home page
        $(".pulsante").css({'border-color': '#262626'});
      } else {
        if (this.categoria1.includes(percorso)) {
          colore = color1;
          pres = 1;
        }
        if (this.categoria2.includes(percorso)) {
          colore = color2;
          pres = 1;
        }
        if (this.categoria3.includes(percorso)) {
          colore = color3;
          pres = 1;
        }

        $(".pulsante").css({'border-color': '#262626'});
        if (pres === 1) {
          $('.' + percorso).css({'border-color': colore});
        }
      }
    }
  },
  watch: {
    $route: function (to) {
      this.coloraBordo(to.path);
      // Stabilisce se mostrare il nome della pagina nell'intestazione (mostrata se la variabile vis assume valore vero)
      let dest = to.path.replace("/","");
      let a = 0;
      if (this.categoria1.includes(dest)) {a = 1;}
      if (this.categoria2.includes(dest)) {a = 1;}
      if (this.categoria3.includes(dest)) {a = 1;}
      this.vis = a === 1;
      this.pagnome = this.nomePag(this.$route.path);
      if(to.path === "/") {
        this.inHome = true;
        this.vis = false;
        if (this.slideCaricate === false) {
          this.caricaSlide();
        }
      }else {
        this.inHome = false;
      }
    }
  },
  created: function() {
    this.coloraBordo('/oratorio');
    this.desktop = this.window_width > 1100;
    this.inHome = this.$route.path === "/";
    if (this.inHome === true) {
      this.caricaSlide();
    }
  },
  mounted: function () {
    /* Imposta altezza dei pulsanti uguale a larghezza */
    let cw;
    cw = $('.pulsante').height();
    $('.pulsante').css({'width': cw + 'px'});
    // Carica le immagini del menu
    $(".pulsante.storia").css("background-image","url(" + this.$imageUrl +  "menu/storia.jpg)");
    $(".pulsante.attivita").css("background-image","url(" + this.$imageUrl +  "menu/attivita.jpg)");
    $(".pulsante.insegne").css("background-image","url(" + this.$imageUrl +  "menu/vesti.jpg)");
    $(".pulsante.cronotassi").css("background-image","url(" + this.$imageUrl +  "menu/cronotassi.jpg)");
    $(".pulsante.cariche").css("background-image","url(" + this.$imageUrl +  "menu/cariche.jpg)");
    $(".pulsante.consiglio").css("background-image","url(" + this.$imageUrl +  "menu/stemma.jpg)");
    $(".pulsante.cappellani").css("background-image","url(" + this.$imageUrl +  "menu/cappellani.jpg)");
    $(".pulsante.statuto").css("background-image","url(" + this.$imageUrl +  "menu/statuto.jpg)");
    $(".pulsante.oratorio").css("background-image","url(" + this.$imageUrl +  "menu/oratorio.jpg)");
    $(".pulsante.basilica").css("background-image","url(" + this.$imageUrl +  "menu/sanquirino_facciata.jpg)");
    $(".pulsante.agiografia").css("background-image","url(" + this.$imageUrl +  "menu/reliquia.jpg)");
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    /* Riduzione dell'intestazione allo scorrimento della pagina */
    /*  let windowsize = $(window).width();
      if (windowsize < 1000) {
        this.desktop = false;
      }else{
        this.desktop = false;
      }*
      $(window).resize(function() {
        let windowsize = $(window).width();
        if (windowsize < 1000) {
          this.desktop = false;
        }else{
          this.desktop = false;
        }
      });
  */


    /* Animazioni */
    const tempo = 1000; // Durata della transizione in ms
    const color1 = 'red';
    const color2 = 'yellow';
    const color3 = '#BAA81F';

    function cambiaSez(v,n) { /* v sezione vecchia, n sezione nuova */
      if ((v===1) && (n===2)) {
        $("#ts2").css({'visibility':'visible'});
        $("#animation_s").animate( {"top": "-=275px"}, tempo, "linear" );
        $("#animation_t").animate( {"top": "-=145px"}, tempo, "linear" );
      }
      if ((v===2) && (n===1)) {
        $("#animation_s").animate( {"top": "+=275px"}, tempo, "linear" );
        $("#animation_t").animate( {"top": "+=145px"}, tempo, "linear" );
      }
      if ((v===1) && (n===3)) {
        $("#ts2").css({'visibility':'hidden'});
        $("#animation_t").animate( {"top": "-=290px"}, tempo, "linear" );
        $("#animation_s").animate( {"top": "-=275px"}, tempo, "linear" );
        $("#animation_d").animate( {"top": "-=275px"}, tempo, "linear" );
      }
      if ((v===3) && (n===1)) {
        $("#ts2").css({'visibility':'hidden'});
        $("#animation_t").animate( {"top": "+=290px"}, tempo, "linear" );
        $("#animation_s").animate( {"top": "+=275px"}, tempo, "linear" );
        $("#animation_d").animate( {"top": "+=275px"}, tempo, "linear" );
      }
      if ((v===2) && (n===3)) {
        $("#animation_t").animate( {"top": "-=145px"}, tempo, "linear" );
        $("#animation_d").animate( {"top": "-=275px"}, tempo, "linear" );
      }
      if ((v===3) && (n===2)) {
        $("#ts2").css({'visibility':'visible'});
        $("#animation_t").animate( {"top": "+=145px"}, tempo, "linear" );
        $("#animation_d").animate( {"top": "+=275px"}, tempo, "linear" );
      }
    }

    let sez_attuale = 1;
    let myClass;


    $("#homelink, .storia, .attivita, .insegne, .cronotassi").unbind().click(function () {
      myClass = $(this).attr("class");
      if (myClass !== 'pulsante_home') {
        //  seleziona(myClass, color1);
      }else{
        // seleziona('.phantom', color1);
      }
      if (sez_attuale !== 1) {
        cambiaSez(sez_attuale, 1);
        sez_attuale = 1;
        $('.menu_sel').css({'border-color':color1});
        $('.sezione_sinistra').css({'border-color':color2});
        $('.sezione_destra').css({'border-color':color3});
      }
    });

    $(".cariche, .cappellani, .consiglio, .statuto").unbind().click(function () {
      myClass = $(this).attr("class");
      //seleziona(myClass,color2);
      if (sez_attuale !== 2) {
        cambiaSez(sez_attuale, 2);
        sez_attuale = 2;
        $('.menu_sel').css({'border-color':color2});
        $('.sezione_sinistra').css({'border-color':color1});
        $('.sezione_destra').css({'border-color':color3});
      }
    });

    $(".oratorio, .basilica, .agiografia").unbind().click(function () {
      myClass = $(this).attr("class");
      //  seleziona(myClass,color3);
      if (sez_attuale !== 3) {
        cambiaSez(sez_attuale, 3);
        sez_attuale = 3;
        $('.menu_sel').css({'border-color':color3});
        $('.sezione_sinistra').css({'border-color':color1});
        $('.sezione_destra').css({'border-color':color2});
      }
    });
    /*
        $("#homelink").click(function () {
          this.$router.push('');
        });

        $(".pulsante").click(function () {
          myClass = $(this).attr("class");
          classe = myClass.split(" ");
          doc = classe[1];
          window.scrollTo(0, 0);
        });
    */
    // Carica le immagini del menu


    // Pulsante per tornare ad inizio pagina
    let btn = $('#topbutton');
    $(window).scroll(function() {
      if ($(window).scrollTop() > 300) {
        btn.addClass('show');
      } else {
        btn.removeClass('show');
      }
    });
    btn.on('click', function(e) {
      e.preventDefault();
      $('html, body').animate({scrollTop:0}, '300');
    });

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }
}

</script>

<style>
@import "risorse/css/stile.css";
@import "risorse/css/stile_mobile.css";
</style>
