import Vue from 'vue'
import Router from 'vue-router'
import pagina from './componenti/pagina'
import articoli from './componenti/articoli'
import login from './componenti/login'
import test from './componenti/test'
import articolo4 from "@/componenti/articolo4.vue";
Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        { path: '/', component: pagina,  props: { pag:'home' }},
        { path: '/storia', component: pagina,  props: { pag:'storia' }},
        { path: '/a', component: articolo4,  props: { pag:'storia' }},
        { path: '/attivita', component: pagina,  props: { pag:'attivita' }},
        { path: '/vesti', component: pagina,  props: { pag:'insegne' }},
        { path: '/insegne', component: pagina,  props: { pag:'insegne' }},
        { path: '/cronotassi', component: pagina,  props: { pag:'cronotassi' }},
        { path: '/cariche', component: pagina,  props: { pag:'cariche' }},
        { path: '/consiglio', component: pagina,  props: { pag:'consiglio' }},
        { path: '/cappellani', component: pagina,  props: { pag:'cappellani' }},
        { path: '/statuto', component: pagina,  props: { pag:'statuto' }},
        { path: '/oratorio', component: pagina,  props: { pag:'oratorio' }},
        { path: '/basilica', component: pagina,  props: { pag:'basilica' }},
        { path: '/agiografia', component: pagina,  props: { pag:'agiografia' }},
        { path: '/articoli/:anno?/:nome?', component: articoli,  props: true},
        { path: '/info-cariche', component: pagina,  props: { pag:'info-cariche' }},
        { path: '/contatti', component: pagina,  props: { pag:'contatti' }},
        { path: '/onlus', component: pagina,  props: { pag:'onlus' }},
        { path: '/bilancio', component: pagina,  props: { pag:'bilancio' }},
        { path: '/login', component: login},
        { path: '/test', component: test},
        // Redirect per alcune vecchie pagine
        { path: '/storia.php', redirect: '/storia'},
        { path: '/attivita.php', redirect: '/attivita'},
        { path: '/cronotassi.php', redirect: '/cronotassi'},
        { path: '/statuto.php', redirect: '/statuto'},
        { path: '/cariche.php', redirect: '/cariche'},
        { path: '/cappellani.php', redirect: '/cappellani'},
        { path: '/basilica.php', redirect: '/basilica'},
        { path: '/agiografia.php', redirect: '/agiografia'},
        { path: '*', component: pagina,  props: { pag:'errore404' }}
    ]
})
